<template>
  <div>
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
    />
  </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: [String, Number],
      default() {
        return 400;
      },
    },
    width: {
      type: [String, Number],
      default() {
        return 500;
      },
    },    
    data: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      series: [],  // Data for the donut chart
      chartOptions: {
        chart: {
          type: 'donut',
          width: '100%',
          height: '100%',
        },
        labels: [],  // Labels for the donut chart
        colors: ['#13DEB9', '#FFAE20', '#FA896B', '#5D87FF'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,  // Disable data labels
        },
        legend: {
          show: false,
        },
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        // Skip "total_count" when mapping the series data
        this.series = this.titles
          .filter((key) => key !== 'total_count') // Filter out 'total_count'
          .map((key) => parseFloat(newData[key]) || 0);
      },
    },
    titles: {
      immediate: true,
      handler(newTitles) {
        // Skip "total_count" when mapping the labels
        this.chartOptions.labels = newTitles
          .filter((title) => title !== 'total_count') // Filter out 'total_count'
          .map((title) => title.replace(/_/g, ' '));
      },
    },
  },
};
</script>